import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Não Encontrado" />
        <h1>Não encontrado</h1>
        <p>Essa página não existe ainda... ah a tristeza.</p>
      </Layout>
    )
  }
}

export default NotFoundPage
